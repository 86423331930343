/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: white !important;
  color: black !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

section {
  padding: 5rem;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 200px;
  height: auto;
}

.img {
  width: 400px;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: white;
}

nav.navbar a.navbar-brand {
  width: 12%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: black !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  color: #2DBC60 !important;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: #2DBC60;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: white;
  border: 1px solid #2DBC60;
  border-radius: 1rem;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: #2DBC60;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #2DBC60;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-position: top center;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: black;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

/************ Planos Css ************/

.planos {
  background: linear-gradient(90.21deg, #2DBC60 -5.91%, #07833f 111.58%);
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  display: flex !important;
  justify-content: space-around !important;
}

.planos h2 {
  font-size: 45px;
  font-weight: 700;
}

.planos p {
  color: black;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.planos-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.planos-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

/************ Contact Css ************/

.contact {
  background: linear-gradient(90.21deg, #2DBC60 -5.91%, #07833f 111.58%);
  padding: 60px 0 200px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 1rem;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  border-radius: 1rem;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/

.footer {
  padding: 50px 0 50px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #2DBC60 -5.91%, #07833f 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  background: linear-gradient(90.21deg, #2DBC60 -5.91%, #07833f 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer img {
  width: 50%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: black;
  letter-spacing: 0.5px;
  margin-top: 20px;
  text-align: center;
}

.whatsButton {
  cursor: pointer;
  right: 40px;
  bottom: 40px;
  position: fixed;
  animation: updown 2s linear infinite;
  z-index: 600;
}

@media (max-width:768px) {
  .whatsButton {
    right: 10px;
  }

  .whatsButton img {
    width: 2.5rem !important;
  }
}

.vantagens-container {
  display: flex;
  flex-direction: column;
}

.btn-primary {
  color: white !important;
  background-color: #2DBC60 !important;
  border-color: #2DBC60 !important;
}

.list-group-item, .modal-header {
  border: none !important;
}

.vantagens-icon {
  color: #2DBC60;
  width: 1.5rem;
  font-size: 4rem;
  padding-right: 10px;
}

.vantagens-list {
  color: black;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.custom-border {
  border: 2px solid #2DBC60 !important;
}

/************ CARDS CSS ************/

.row {
  display: flex;
}

.card {
  border-radius: 20px !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 1rem !important;
  width: 100% !important;
}

.card-body {
  justify-content: center !important;
  align-items: center !important;
}

.card-title {
  justify-content: center !important;
  align-items: center !important;
}

.card-header {
  border: none !important;
  background-color: transparent !important;
}

.card-text {
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
}

.titulo {
  text-align: center;
}

#text-black {
  color: black !important;
}

#beneficios {
  background-color: #DCD6D1;
}

.row-footer {
  display: flex;
  flex-direction: row;
}

.modal-content {
  border-radius: 1rem !important;
}

.modal-title {
  text-align: center;
  width: 100%;
}


@media (max-width:768px) {
  .footer {
    margin: auto;
  }

  .footer-logo {
    display: flex;
    justify-content: center;
  }

  .row-footer {
    display: flex;
    flex-direction: column;
  }

  .logo-footer {
    margin-bottom: 20px;
  }
}

@media (max-width:768px) {
  section {
    padding: 1rem;
  }
}

@media (max-width:768px) {
  #copyright {
    margin-top: 2rem !important;
    width: 100% !important;
    text-align: center;
  }
}

@media (max-width:768px) {
  .planos {
    flex-direction: column;
  }
}

@media (max-width:768px) {
  Navbar {
    display: none !important;
  }
}

@media (max-width:768px) {
  .navbar-text {
    display: none !important;
  }
}

@media (max-width:768px) {
  .vantagens-list {
    align-items: start;
    padding: 10px;
  }
}

@media (max-width:768px) {
  .funcionalidades-cards {
    width: 100% !important;
    margin: 10px !important;
  }

  .custom-border {
    height: 10% !important;
  }
}

@media (max-width:768px) {
  nav.navbar {
    position: fixed;
  }
}

@media (max-width:768px) {
  .img-banner {
    display: none;
  }
}